/* eslint-disable max-lines */
import type {
  AuthLoginRequestDTO,
  AuthLoginResponseDTO,
  AuthRefreshResponseDTO,
  AuthRegisterRequestDTO,
  CheckCensusRequestDTO,
  CheckCensusResponseDTO,
  EmailAddressAvailableRequestDTO,
  EmailAddressAvailableResponseDTO,
  UserNameAvailableRequestDTO,
  UserNameAvailableResponseDTO,
} from "@/ts/types/dto/auth.dto";
import {HttpWrapper} from "@/ts/classes/http-wrapper";
import type {
  DeleteAccountRequestDTO,
  GetProfileResponseDTO,
  PutProfileRequestDTO,
  PutProfileResponse,
  UserInterestDTO,
} from "@/ts/types/dto/profile.dto";
import type {SessionHolder} from "@/ts/types";
import type {PutDepartmentRequestDTO, PutDepartmentResponseDTO} from "@/ts/types/dto/department.dto";
import type {PutLocationRequestDTO, PutLocationResponseDTO} from "@/ts/types/dto/location.dto";
import type {
  GetPathwayRequestDTO,
  GetPathwayResponseDTO,
  GetPathwaysRequestDTO,
  GetPathwaysResponseDTO,
  PostQuizRequestRequestDTO,
  PostQuizResponse,
  PutLastSectionReadRequestDTO,
  PutPathwayToTodoResponseDTO,
} from "@/ts/types/dto/pathway.dto";
import type {
  GetActivitiesRequestDTO,
  GetActivitiesResponseDTO,
  GetPlatformActivitiesResponseDTO,
  PlatformActivityDTO,
  PostActivityFinishHonorRequestDTO,
  PostActivityFinishProofRequestDTO,
  PutActivityToTodoResponseDTO,
  PutPlatformActivityToTodoResponseDTO,
} from "@/ts/types/dto/activity.dto";
import type {GetTodoRequestDTO, TodoItemDto} from "@/ts/types/dto/todo.dto";
import type {GatekeeperItem, GetContentCategoryRequestDTO, GetContentCategoryResponseDTO, GetProgramResponseDTO, MyProgramResponse} from "@/ts/types/dto/program.dto";
import type {GetRewardTrackerDTO} from "@/ts/types/dto/reward-tracker";
import type {RedeemCashResponseDTO} from "@/ts/types/dto/redeem.dto";
import type {BiometricSchedulingWebResponseDTO, RedeemIncentiveWebResponseDTO} from "@/ts/types/dto/web.dto";
import type {
  LeaderboardChallengeDTO,
  LeaderboardChallengeParticipants,
  LeaderboardChallengeRanking,
  MeGetChallengesRequestDTO,
} from "@/ts/types/dto/leaderboard-challenge.dto";
import type {
  BiometricScreening,
  BiometricScreeningAdditionalResult,
  BiometricScreeningAvailable,
  BiometricScreeningFaxDetail,
  BiometricScreeningFaxRequest,
  BiometricScreeningResult,
  BiometricScreeningScheduler,
  BiometricScreeningStatus,
  BiometricScreeningTestKitDTO,
  BiometricScreeningWrapperResult,
  GetBiometricScreeningAvailableLocationDateRequest,
  GetBiometricScreeningAvailableLocationDateSlotsRequest,
  GetBiometricScreeningAvailableLocationDateSlotsResponse,
  GetBiometricScreeningAvailableLocationDatesResponse,
  GetBiometricScreeningAvailableLocationsResponse,
  GetBiometricScreeningScheduleAppointmentDTO,
  PhysicianFormAvailable,
  PostBiometricScheduleAppointmentRequest,
  PostBiometricScreeningScheduleAppointmentResponse,
} from "@/ts/types/dto/biometric-screening.dto";
import type {
  GetCoachingAvailableDateSlotsRequest,
  GetCoachingAvailableDateSlotsResponse,
  GetCoachingAvailableDatesResponse,
  GetCoachingScheduleAppointmentDTO,
  PostCoachingAppointmentRequest,
  PostCoachingScheduleAppointmentResponse,
} from "@/ts/types/dto/coaching.dto";
import type {
  GetTrackingContentEligibilityDTO,
  GetTrackingListRequestDTO,
  GetTrackingListResponseDTO,
  PutTrackingRequestDTO,
  GetPaginatedTrackingRequestDTO,
  GetPaginatedTrackingResponseDTO,
} from "@/ts/types/dto/tracking.dto";
import type {
  GetPhysicianFormBiometricResponse,
  PhysicianFormBodyRequest,
  PostPhysicianForm,
} from "@/ts/types/dto/physician-form.dto";
import type {
  ChangePasswordRequestDTO,
  CheckPasswordRecoveryTokenRequestDTO,
  RequestRecoveryEmailDTO,
  ResetPasswordRequestDTO,
} from "@/ts/types/dto/password.dto";
import type {RequestUsernameDTO} from "@/ts/types/dto/username.dto";
import type {HRASummary, HRASurvey, HRASurveyAnswer} from "@/ts/types/dto/health-risk-assessment.dto";
import type {ChallengeDTO, PaginatedChallengeDTO} from "@/ts/types/dto/challenges.dto";
import type {ImageData} from "@/ts/types/dto/image-data.dto";
import type {Feed, FeedItem, GetFeedRequestParams} from "@/ts/types/dto/feed.dto";
import type {FriendDto, FriendListResponse, InviteFriendsPayload} from "@/ts/types/dto/friend.dto";
import type {GetRewardHistoryRequestDTO, GetRewardHistoryResponseDTO} from "@/ts/types/dto/reward.dto";
import { GetBenefitsResponseDTO } from "@/ts/types/dto/benefits.dto";
import { CompanyCodeDTO, CompanySSODTO, CompanySSOResponseDTO } from "@/ts/types/dto/company-code.dto";
import {MfaVerificationRequestDTO} from "@/ts/types/dto/auth.dto";
import { CreateGoalResponse, GetGoalsResponseType, GoalCreateType } from "@/ts/types/dto/goal.dto";
import { HealthContentActivityResponseType} from "@/ts/types/dto/health.dto";
import {GetTrackingMetricsRequestDTO, GetTrackingMetricsResponseDTO} from "@/ts/types/dto/tracking.dto";
import {
  GroupPostComment,
  GroupPostCreate, GroupPostLike, GroupPostResponse,
  PaginatedUserGroupFeedResponse,
  PaginatedUserGroupResponse,
  UserGroup
} from "@/ts/types/dto/group.dto";


const BIOMETRIC_SCREENING_SCHEDULE_APPOINTMENT_URL = "/me/biometric-screening-schedule-appointment";

const COACHING_SCHEDULE_APPOINTMENT_URL = "/me/coaching-schedule-appointment";

export class Api {
  private readonly httpWrapper: HttpWrapper;

  constructor(
    backendUrl: string,
    private readonly sessionStorage: SessionHolder,
    private readonly onTokenRefresh: () => Promise<AuthRefreshResponseDTO>,
  ) {
    this.httpWrapper = new HttpWrapper(backendUrl, onTokenRefresh);
  }

  public async login(body: AuthLoginRequestDTO): Promise<AuthLoginResponseDTO> {
    return this.httpWrapper.post<AuthLoginResponseDTO>({
      url: "/auth/login",
      body,
    });
  }

  public async verifyMfaToken(body: MfaVerificationRequestDTO): Promise<AuthLoginResponseDTO> {
    try {
      console.log(body);
      return await this.httpWrapper.post<AuthLoginResponseDTO>({
        url: "/auth/mfa-verify",
        body,
      });
    } catch (err) {
      console.error(err);
      throw new Error("MFA verification failed");
    }
  }


  public async loginToken(authCode: string): Promise<AuthLoginResponseDTO> {
    return this.httpWrapper.post<AuthLoginResponseDTO>({
      url: "/auth/login-token",
      body: {authCode},
    });
  }


  public async logout(): Promise<void> {
    await this.httpWrapper.post({
      url: "/auth/logout",
      authorization: this.sessionStorage.sessionToken!,
    });
  }


  public async getRedirectToKeycloakUrl(): Promise<{authUrl: string}> {
    const url = new URL(window.location.href);
    const provider = url.searchParams.get("provider");
    const clientPlatform = url.searchParams.get("clientPlatform");
    return this.httpWrapper.get<{authUrl: string}>({
      queryParams: {provider,clientPlatform},
      url: "/auth/redirect",
    });
  }

  public async handleOIDCCallback(code: string, state: string): Promise<AuthLoginResponseDTO> {
    return this.httpWrapper.post<AuthLoginResponseDTO>({
      url: "/auth/callback",
      body: {code, state},
    });
  }

  public async authorizeWithProvider(): Promise<any> {
    const queryParams = new URL(window.location.href).searchParams;
    const responseType = queryParams.get("response_type");
    const clientId = queryParams.get("client_id");
    const scope = queryParams.get("scope");
    const redirectUri = queryParams.get("redirect_uri");
    const state = queryParams.get("state");

    if (!responseType || !clientId || !scope || !redirectUri || !state) {
      throw new Error("Required parameters are missing in the callback URL");
    }

    return this.httpWrapper.get<any>({
      url: "/oidc/auth",
      queryParams: {responseType,
clientId,
scope,
redirectUri,
state},
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getImage(path: string): Promise<string> {
    try {
      const data: Blob = await this.httpWrapper.get<Blob>({
        url: `/image/raw:true/path/${path}`,
        authorization: this.sessionStorage.sessionToken!,
      });

      return URL.createObjectURL(data);
    } catch (error) {
      console.error("Error fetching image:", error);
      throw error;
    }
  }

  public async getActivityCategories(queryParams?: GetContentCategoryRequestDTO): Promise<GetContentCategoryResponseDTO[]> {
    return this.httpWrapper.get<GetContentCategoryResponseDTO[]>({
      url: "/me/content-categories/activity",
      queryParams,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getActivities(queryParams?: GetActivitiesRequestDTO): Promise<GetActivitiesResponseDTO> {
    return this.httpWrapper.get<GetActivitiesResponseDTO>({
      url: "/me/activity",
      queryParams,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getPlatformActivities(queryParams?: GetActivitiesRequestDTO): Promise<GetPlatformActivitiesResponseDTO> {
    return this.httpWrapper.get<GetPlatformActivitiesResponseDTO>({
      url: "/me/platform-activity-paginated",
      queryParams,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getActivityById(
    activityId: string,
  ): Promise<PutActivityToTodoResponseDTO> {
    return this.httpWrapper.get<PutActivityToTodoResponseDTO>({
      url: `/me/activity/${activityId}`,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getPlatformActivityById(
    platformActivityId: string,
  ): Promise<PlatformActivityDTO> {
    return this.httpWrapper.get<PlatformActivityDTO>({
      url: `/me/platform-activity/${platformActivityId}`,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async addActivityToTodo(activityId: string): Promise<PutActivityToTodoResponseDTO> {
    return this.httpWrapper.put<PutActivityToTodoResponseDTO>({
      url: `/me/activity/${activityId}/todo`,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async addPlatformActivityToTodo(activityId: string): Promise<PutPlatformActivityToTodoResponseDTO> {
    return this.httpWrapper.put<PutPlatformActivityToTodoResponseDTO>({
      url: `/me/platform-activity/${activityId}/todo`,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async submitHonorActivity(
    activityId: string,
    body: PostActivityFinishHonorRequestDTO,
  ): Promise<void> {
    return this.httpWrapper.post<undefined>({
      url: `/me/activity/${activityId}/completion/honor`,
      body,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getBenefits(): Promise<GetBenefitsResponseDTO> {
    return this.httpWrapper.get<GetBenefitsResponseDTO>({
      url: "/me/benefit",
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async redeemCash(): Promise<RedeemCashResponseDTO> {
    return this.httpWrapper.post<RedeemCashResponseDTO>({
      url: "/me/redeem-cash",
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getRedeemCashStatus(): Promise<RedeemCashResponseDTO> {
    return this.httpWrapper.get<RedeemCashResponseDTO>({
      url: "/me/redeem-cash",
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getActivityCompletionProof(
    activityId: string,
  ): Promise<PostActivityFinishProofRequestDTO> {
    return this.httpWrapper.get<PostActivityFinishProofRequestDTO>({
      url: `/me/activity/${activityId}/completion/proof`,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async submitProofActivity(
    activityId: string,
    file: File,
  ): Promise<PostActivityFinishProofRequestDTO> {
    const body: FormData = new FormData();

    body.append("proof", file);
    return this.httpWrapper.postMultiData<PostActivityFinishProofRequestDTO>({
      url: `/me/activity/${activityId}/completion/proof`,
      body,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async submitQuiz(
    pathwayId: string,
    body: PostQuizRequestRequestDTO,
  ): Promise<PostQuizResponse> {
    return this.httpWrapper.post<PostQuizResponse>({
      url: `/me/pathway/${pathwayId}/quiz`,
      body,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async putLastSectionRead(
    pathwayId: string,
    body: PutLastSectionReadRequestDTO,
  ): Promise<void> {
    return this.httpWrapper.put<undefined>({
      url: `/me/pathway/${pathwayId}/last-section-read`,
      body,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getTodo(
    queryParams: GetTodoRequestDTO,
  ): Promise<TodoItemDto[]> {
    return this.httpWrapper.get<TodoItemDto[]>({
      url: "/me/todo",
      queryParams,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getPathwayCategories(queryParams?: GetContentCategoryRequestDTO): Promise<GetContentCategoryResponseDTO[]> {
    return this.httpWrapper.get<GetContentCategoryResponseDTO[]>({
      url: "/me/content-categories/pathway",
      queryParams,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getPathway(
    body: GetPathwayRequestDTO,
  ): Promise<GetPathwayResponseDTO> {
    return this.httpWrapper.get<GetPathwayResponseDTO>({
      url: `/me/pathway/${body.id}`,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getPathways(queryParams?: GetPathwaysRequestDTO): Promise<GetPathwaysResponseDTO> {
    return this.httpWrapper.get<GetPathwaysResponseDTO>({
      url: "/me/pathway",
      queryParams,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async usernameAvailable(
    queryParams: UserNameAvailableRequestDTO,
    checkCensusToken: string,
  ): Promise<UserNameAvailableResponseDTO> {
    return this.httpWrapper.get<UserNameAvailableResponseDTO>({
      url: "/auth/username-available",
      queryParams,
      authorization: checkCensusToken,
      skipAuthRefresh: true,
    });
  }

  public async emailAddressAvailable(
    queryParams: EmailAddressAvailableRequestDTO,
  ): Promise<UserNameAvailableResponseDTO> {
    return this.httpWrapper.get<EmailAddressAvailableResponseDTO>({
      url: "/auth/email-available",
      queryParams,
    });
  }

  public async getProfile(): Promise<GetProfileResponseDTO> {
    return this.httpWrapper.get<GetProfileResponseDTO>({
      url: "/me/profile",
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async deleteActivity(activityId: string): Promise<void> {
    return this.httpWrapper.delete<undefined>({
      url: `/me/activity/${activityId}/todo`,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async deletePlatformActivity(activityId: string): Promise<void> {
    return this.httpWrapper.delete<undefined>({
      url: `/me/platform-activity/${activityId}/todo`,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async completeActivityByHonor(activityId: string): Promise<void> {
    return this.httpWrapper.post<undefined>({
      url: `/me/activity/${activityId}/completion/honor`,
      body: {progressDelta: 0},
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async updateProfile(
    body: PutProfileRequestDTO,
  ): Promise<PutProfileResponse> {
    return this.httpWrapper.put<PutProfileResponse>({
      url: "/me/profile",
      authorization: this.sessionStorage.sessionToken!,
      body,
    });
  }

  public async uploadProfileImage(file: File): Promise<{path: string}> {
    const body: FormData = new FormData();
    body.append("fileupload", file);
    return this.httpWrapper.postMultiData<{path: string}>({
      url: "/me/profile/image",
      body,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async updateDepartment(
    body: PutDepartmentRequestDTO,
  ): Promise<PutDepartmentResponseDTO> {
    return this.httpWrapper.put<PutDepartmentResponseDTO>({
      url: "/me/department",
      authorization: this.sessionStorage.sessionToken!,
      body,
    });
  }

  public async updateLocation(
    body: PutLocationRequestDTO,
  ): Promise<PutLocationResponseDTO> {
    return this.httpWrapper.put<PutLocationResponseDTO>({
      url: "/me/location",
      authorization: this.sessionStorage.sessionToken!,
      body,
    });
  }

  public async checkCensus(
    body: CheckCensusRequestDTO,
  ): Promise<CheckCensusResponseDTO> {
    return this.httpWrapper.post<CheckCensusResponseDTO>({
      url: "/auth/check-census",
      body,
    });
  }

  public async addPathwayToTodo(
    pathwayId: string,
  ): Promise<PutPathwayToTodoResponseDTO> {
    return this.httpWrapper.put<PutPathwayToTodoResponseDTO>({
      url: `/me/pathway/${pathwayId}/todo`,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async register(
    body: AuthRegisterRequestDTO,
    checkCensusToken: string,
  ): Promise<void> {
    return this.httpWrapper.post({
      url: "/auth/register",
      body,
      authorization: checkCensusToken,
      skipAuthRefresh: true,
    });
  }

  public async registerSso(
    body: AuthRegisterRequestDTO,
    checkCensusToken: string,
  ): Promise<AuthLoginResponseDTO> {
    return this.httpWrapper.post({
      url: "/auth/register-sso",
      body,
      authorization: checkCensusToken,
      skipAuthRefresh: true,
    });
  }
  public async getProgram(handle404?: true): Promise<GetProgramResponseDTO> {
    return this.httpWrapper.get<GetProgramResponseDTO>({
      url: "/me/program",
      authorization: this.sessionStorage.sessionToken!,
      handle404,
    });
  }

  public async getRewardTracker(): Promise<GetRewardTrackerDTO> {
    return this.httpWrapper.get<GetRewardTrackerDTO>({
      url: "/me/reward/tracker",
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getIncentiveWeb(): Promise<RedeemIncentiveWebResponseDTO> {
    return this.httpWrapper.get<RedeemIncentiveWebResponseDTO>({
      url: "/me/redeem-incentiveweb",
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getChallenges(queryParams?: MeGetChallengesRequestDTO): Promise<PaginatedChallengeDTO> {
    return this.httpWrapper.get<PaginatedChallengeDTO>({
      url: "/me/challenge-paginated",
      queryParams: queryParams,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getTrackingList(queryParams: GetTrackingListRequestDTO): Promise<GetTrackingListResponseDTO[]> {
    return this.httpWrapper.get<GetTrackingListResponseDTO[]>({
      url: "/me/tracking",
      queryParams,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getPaginatedTrackingList(queryParams: GetPaginatedTrackingRequestDTO): Promise<GetPaginatedTrackingResponseDTO> {
    return this.httpWrapper.get<GetPaginatedTrackingResponseDTO>({
      url: "/me/paginated-tracking",
      queryParams,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getTrackingMetrics(queryParams: GetTrackingMetricsRequestDTO): Promise<GetTrackingMetricsResponseDTO> {
    return this.httpWrapper.get<GetTrackingMetricsResponseDTO>({
      url: "/me/tracking-metrics",
      queryParams,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async postTrackingAmount(
    body: PutTrackingRequestDTO,
  ): Promise<PutTrackingRequestDTO> {
    return this.httpWrapper.post<PutTrackingRequestDTO>({
      url: "/me/tracking",
      body,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async changePassword(body: ChangePasswordRequestDTO): Promise<void> {
    return this.httpWrapper.put<undefined>({
      url: "/me/password",
      body,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async deleteAccount(body: DeleteAccountRequestDTO): Promise<void> {
    return this.httpWrapper.post<undefined>({
      url: "/me/anonymize",
      body,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getLeaderboardChallenge(
    challengeId: string,
  ): Promise<LeaderboardChallengeDTO> {
    return this.httpWrapper.get<LeaderboardChallengeDTO>({
      url: `/me/challenge-leaderboard/${challengeId}`,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getLeaderboardChallengeParticipants(
    challengeId: string,
  ): Promise<LeaderboardChallengeParticipants> {
    return this.httpWrapper.get<LeaderboardChallengeParticipants>({
      url: `/me/challenge-leaderboard/${challengeId}/participants`,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async postLeaderboardChallenge(
    challengeId: string,
  ): Promise<LeaderboardChallengeDTO> {
    return this.httpWrapper.post<LeaderboardChallengeDTO>({
      url: `/me/challenge-leaderboard/${challengeId}/join`,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getLeaderboardChallengeRanking(
    challengeId: string,
  ): Promise<LeaderboardChallengeRanking> {
    return this.httpWrapper.get<LeaderboardChallengeRanking>({
      url: `/me/challenge-leaderboard/${challengeId}/ranking`,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getBiometricScreeningScheduleAppointment(): Promise<GetBiometricScreeningScheduleAppointmentDTO | null> {
    return this.httpWrapper.get<GetBiometricScreeningScheduleAppointmentDTO>({
      url: BIOMETRIC_SCREENING_SCHEDULE_APPOINTMENT_URL,
      authorization: this.sessionStorage.sessionToken!,
      handle404: true,
    });
  }

  public async getBiometricScreeningScheduleStart(): Promise<BiometricScreeningScheduler> {
    let result: any;

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      result = await this.httpWrapper.get<any>({
        url: "/me/biometric-screening-schedule/start",
        authorization: this.sessionStorage.sessionToken!,
        handle404: true,
      });
    } catch {
      // eslint-disable-line
    }

    return {
      sourceType: result?.schedulerSource?.sourceType ?? "InternalRequest", // eslint-disable-line
      url: result?.schedulerSource?.url, // eslint-disable-line
    };
  }

  public async getBiometricSchedulingSSOUrl(): Promise<BiometricSchedulingWebResponseDTO> {
    return this.httpWrapper.get<BiometricSchedulingWebResponseDTO>({
      url: "/oidc/url",
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getBiometricScreeningLocations(): Promise<GetBiometricScreeningAvailableLocationsResponse> {
    return this.httpWrapper.get<GetBiometricScreeningAvailableLocationsResponse>({
      url: "/me/biometric-screening-available-locations",
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getBiometricScreeningAvailableLocationDates(
    queryParams: GetBiometricScreeningAvailableLocationDateRequest,
  ): Promise<GetBiometricScreeningAvailableLocationDatesResponse> {
    return this.httpWrapper.get<GetBiometricScreeningAvailableLocationDatesResponse>({
      url: "/me/biometric-screening-available-location-dates",
      queryParams,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getBiometricScreeningAvailableLocationDateSlots(
    queryParams: GetBiometricScreeningAvailableLocationDateSlotsRequest,
  ): Promise<GetBiometricScreeningAvailableLocationDateSlotsResponse> {
    return this.httpWrapper.get<GetBiometricScreeningAvailableLocationDateSlotsResponse>({
      url: "/me/biometric-screening-available-location-date-slots",
      queryParams,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async postBiometricScheduleAppointment(
    body: PostBiometricScheduleAppointmentRequest,
  ): Promise<PostBiometricScreeningScheduleAppointmentResponse> {
    return this.httpWrapper.post<PostBiometricScreeningScheduleAppointmentResponse>({
      url: BIOMETRIC_SCREENING_SCHEDULE_APPOINTMENT_URL,
      body,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async deleteBiometricScheduleAppointment(): Promise<void> {
    return this.httpWrapper.delete<undefined>({
      url: BIOMETRIC_SCREENING_SCHEDULE_APPOINTMENT_URL,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async checkPasswordRecoveryToken(
    body: CheckPasswordRecoveryTokenRequestDTO,
  ): Promise<Response> {
    return this.httpWrapper.post<Response>({
      url: "/auth/password-recovery/check-token",
      body,
    });
  }

  public async resetPassword(body: ResetPasswordRequestDTO): Promise<Response> {
    return this.httpWrapper.post<Response>({
      url: "/auth/password-recovery/change-password",
      body,
    });
  }

  public async requestRecoveryEmail(
    body: RequestRecoveryEmailDTO,
  ): Promise<undefined> {
    return this.httpWrapper.post<undefined>({
      url: "/auth/password-recovery/request-email",
      body,
    });
  }

  public async requestUsername(
    body: RequestUsernameDTO,
  ): Promise<undefined> {
    return this.httpWrapper.post<undefined>({
      url: "/auth/username-recovery/email",
      body,
    });
  }

  public async checkCompanyCode(body: CompanyCodeDTO): Promise<boolean> {
    try {
      return await this.httpWrapper.post<boolean>({
        url: "/auth/check-company-code",
        body,
        authorization: this.sessionStorage.sessionToken!,
      });
    } catch (error) {
      console.error("Error checking company code:", error);
      throw error;
    }
  }

  public async checkCompanySso(body: CompanySSODTO): Promise<CompanySSOResponseDTO> {
    try {
      return await this.httpWrapper.post<CompanySSOResponseDTO>({
        url: "/auth/check-company-sso",
        body,
        authorization: this.sessionStorage.sessionToken!,
      });
    } catch (error) {
      console.error("Error checking company sso:", error);
      throw error;
    }
  }
  public async confirmMail(token: string): Promise<undefined> {
    return this.httpWrapper.post<undefined>({
      url: "/auth/email-confirm/check-token",
      body: {token},
    });
  }

  public async getCoachingScheduleAppointment(): Promise<GetCoachingScheduleAppointmentDTO | null> {
    return this.httpWrapper.get<GetCoachingScheduleAppointmentDTO>({
      url: COACHING_SCHEDULE_APPOINTMENT_URL,
      authorization: this.sessionStorage.sessionToken!,
      handle404: true,
    });
  }

  public async getCoachingAvailableDates(): Promise<GetCoachingAvailableDatesResponse> {
    return this.httpWrapper.get<GetCoachingAvailableDatesResponse>({
      url: "/me/coaching-available-dates",
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getCoachingAvailableDateSlots(
    queryParams: GetCoachingAvailableDateSlotsRequest,
  ): Promise<GetCoachingAvailableDateSlotsResponse> {
    return this.httpWrapper.get<GetCoachingAvailableDateSlotsResponse>({
      url: "/me/coaching-available-date-slots",
      queryParams,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async postCoachingScheduleAppointment(
    body: PostCoachingAppointmentRequest,
  ): Promise<PostCoachingScheduleAppointmentResponse> {
    return this.httpWrapper.post<PostCoachingScheduleAppointmentResponse>({
      url: COACHING_SCHEDULE_APPOINTMENT_URL,
      body,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async deleteCoachingScheduleAppointment(): Promise<void> {
    return this.httpWrapper.delete<undefined>({
      url: COACHING_SCHEDULE_APPOINTMENT_URL,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getHRASummary(): Promise<HRASummary> {
    return this.httpWrapper.get<HRASummary>({
      url: "/me/health-risk-assessment-summary",
      authorization: this.sessionStorage.sessionToken!,
      handle404: true,
    });
  }

  public async getHRASurvey(id: string): Promise<HRASurvey> {
    return this.httpWrapper.get<HRASurvey>({
      url: `/me/health-risk-assessment-survey/${id}`,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async postHRASurveyAnswer(body: HRASurveyAnswer): Promise<void> {
    return this.httpWrapper.post<undefined>({
      url: "/me/health-risk-assessment-survey-answer",
      body,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async putHRASurveyComplete(id: string): Promise<void> {
    return this.httpWrapper.put<undefined>({
      url: `/me/health-risk-assessment-survey-complete/${id}`,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getBiometricScreeningAvailable(): Promise<BiometricScreeningAvailable> {
    return this.httpWrapper.get<BiometricScreeningAvailable>({
      url: "/me/biometric-screening-available",
      authorization: this.sessionStorage.sessionToken!,
      handle404: true,
    });
  }

  public async getBiometricScreeningResultCurrent(): Promise<BiometricScreeningWrapperResult> {
    return this.httpWrapper.get<BiometricScreeningWrapperResult>({
      url: "/me/biometric-screening-result-current",
      authorization: this.sessionStorage.sessionToken!,
      handle404: true,
    });
  }

  public async getBiometricScreeningStatus(): Promise<BiometricScreeningStatus> {
    return this.httpWrapper.get<BiometricScreeningStatus>({
      url: "/me/biometric-screening-status",
      authorization: this.sessionStorage.sessionToken!,
      handle404: true,
    });
  }

  public async getBiometricScreeningTestKit(): Promise<BiometricScreeningTestKitDTO> {
    return this.httpWrapper.get<BiometricScreeningTestKitDTO>({
      url: "/me/biometric-screening-test-kit",
      authorization: this.sessionStorage.sessionToken!,
      handle404: true,
    });
  }

  public async setBiometricScreeningTestKitVisited(): Promise<any> {
    return this.httpWrapper.post({
      url: "/me/biometric-screening-test-kit-visited",
      authorization: this.sessionStorage.sessionToken!,
      handle404: true,
    });
  }

  public async getPhysicianFormAvailable(): Promise<PhysicianFormAvailable> {
    return this.httpWrapper.get<PhysicianFormAvailable>({
      url: "/me/physician-form-available",
      authorization: this.sessionStorage.sessionToken!,
      handle404: true,
    });
  }

  public async getPhysicianBiometricForm(): Promise<GetPhysicianFormBiometricResponse> {
    return this.httpWrapper.get<GetPhysicianFormBiometricResponse>({
      url: "/me/physician-form",
      authorization: this.sessionStorage.sessionToken!,
      handle404: true,
    });
  }

  public async downloadPhysicianForm(): Promise<Blob> {
    return this.httpWrapper.get<Blob>({
      url: "/me/physician-form/download",
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async postPhysicianForm(
    physicianForm: PostPhysicianForm,
    file: File,
  ): Promise<PhysicianFormBodyRequest> {
    const body: FormData = new FormData();

    const details = JSON.stringify(physicianForm);

    body.append("physicianFormDetails", details);
    body.append("physicianFormFile", file);

    return this.httpWrapper.postMultiData<PhysicianFormBodyRequest>({
      url: "/me/physician-form",
      body,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async refreshToken(): Promise<AuthRefreshResponseDTO> {
     return this.httpWrapper.post<AuthRefreshResponseDTO>({
      url: "/auth/refresh-token",
      body: {
        refreshToken: this.sessionStorage.refreshToken,
      },
    });
  }

  public async getFullInterestList(): Promise<UserInterestDTO[]> {
    return this.httpWrapper.get<UserInterestDTO[]>({
      url: "/content-category",
      authorization: this.sessionStorage.sessionToken!,
    });
  }

// 'imageProcessingOptions' is a string that needs to fit to the processing-options of image-proxy: https://docs.imgproxy.net/generating_the_url?id=processing-options
  public async getProtectedImage(path: string, imageProcessingOptions?: string): Promise<ImageData> {
    const data: Blob = await this.httpWrapper.get<Blob>({
      url: `/image/${imageProcessingOptions || "raw:true"}/path/${path}`,
      authorization: this.sessionStorage.sessionToken!,
    });

    const imageObjectUrl = URL.createObjectURL(data);

    const imgBoundaries: {width: number; height: number} = await new Promise((resolve, reject) => {
      const img = window.document.createElement("img");
      img.onload = () => {
        resolve({
          width: img.width,
          height: img.height,
        });
      };
      img.onerror = reject;
      img.src = imageObjectUrl;
    });

    return {
      path,
      imageObjectUrl,
      ...imgBoundaries,
      ratio: imgBoundaries.width / imgBoundaries.height,
    } as ImageData;
  }

  public async getFeed(queryParams: GetFeedRequestParams): Promise<Feed> {
    return this.httpWrapper.get<Feed>({
      url: "/me/feed",
      queryParams,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async putLikeFeedItem(feedItemId: string, isLiked: boolean): Promise<FeedItem> {
    return this.httpWrapper.put<FeedItem>({
      url: `/feed/${feedItemId}/like`,
      body: {isLiked},
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getFriends(): Promise<FriendListResponse> {
    return this.httpWrapper.get<FriendListResponse>({
      url: "/me/friend",
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getFriendsRequested(): Promise<FriendListResponse> {
    return this.httpWrapper.get<FriendListResponse>({
      url: "/me/friend/requests-sent",
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getFriendsRequestReceived(): Promise<FriendListResponse> {
    return this.httpWrapper.get<FriendListResponse>({
      url: "/me/friend/requests-received",
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async deleteFriendship(id: string): Promise<void> {
    return this.httpWrapper.delete({
      url: `/me/friend/${id}`,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async deleteMyFriendshipRequest(id: string): Promise<void> {
    return this.httpWrapper.delete({
      url: `/me/friend/${id}/cancel-invite`,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async acceptFriendship(id: string): Promise<FriendDto> {
    return this.httpWrapper.put<FriendDto>({
      url: `/me/friend/${id}/accept`,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async rejectFriendship(id: string): Promise<void> {
    return this.httpWrapper.put({
      url: `/me/friend/${id}/reject`,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getEligibleFriends(query: string): Promise<FriendListResponse> {
    return this.httpWrapper.get({
      url: "/me/eligible-friends",
      authorization: this.sessionStorage.sessionToken!,
      queryParams: {
        query,
        limit: 9, // nine items is optimized for the invite-friends-dialog
        offset: 0,
      },
    });
  }

  public async inviteFriends(payload: InviteFriendsPayload): Promise<void> {
    return this.httpWrapper.post({
      url: "/me/friend/invite",
      authorization: this.sessionStorage.sessionToken!,
      body: payload,
    });
  }

  public async getBiometricScreenings(): Promise<BiometricScreening[]> {
    return this.httpWrapper.get<BiometricScreening[]>({
      url: "/me/biometric-screenings",
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getBiometricScreening(screeningId: number): Promise<BiometricScreeningWrapperResult> {
    return this.httpWrapper.get<BiometricScreeningWrapperResult>({
      url: `/me/biometric-screening/${screeningId}`,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getBiometricScoredResult(screeningId: number, biometricResultId: number): Promise<BiometricScreeningResult> {
    return this.httpWrapper.get<BiometricScreeningResult>({
      url: `/me/biometric-screening/${screeningId}/scored-result/${biometricResultId}`,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getBiometricAdditionalResult(
      screeningId: number, biometricResultId: number,
  ): Promise<BiometricScreeningAdditionalResult> {
    return this.httpWrapper.get<BiometricScreeningAdditionalResult>({
      url: `/me/biometric-screening/${screeningId}/additional-result/${biometricResultId}`,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getBiometricScreeningPDF(screeningId: number): Promise<Blob> {
    return this.httpWrapper.get<Blob>({
      url: `/me/biometric-screening/${screeningId}/download`,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getBiometricScreeningFaxDetails(screeningId: number): Promise<BiometricScreeningFaxDetail> {
    return this.httpWrapper.get<BiometricScreeningFaxDetail>({
      url: `/me/biometric-screening/${screeningId}/fax-details`,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async faxBiometricScreening(screeningId: number, body: BiometricScreeningFaxRequest): Promise<void> {
    return this.httpWrapper.post({
      url: `/me/biometric-screening/${screeningId}/fax`,
      authorization: this.sessionStorage.sessionToken!,
      body,
    });
  }

  public async getRewardHistory(queryParams?: GetRewardHistoryRequestDTO): Promise<GetRewardHistoryResponseDTO> {
    return this.httpWrapper.get<GetRewardHistoryResponseDTO>({
      url: "/me/reward/history-paginated",
      queryParams,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getGoals(
    limit: number,
    offset: number,
    onlyFinished?: boolean,
    onlyActive?: boolean,
  ): Promise<GetGoalsResponseType> {
    const queryParams: Record<string, any> = {
      limit,
      offset,
    };

    if (onlyFinished !== undefined) {
      queryParams.onlyFinished = onlyFinished;
    }

    if (onlyActive !== undefined) {
      queryParams.onlyActive = onlyActive;
    }

    return this.httpWrapper.get<GetGoalsResponseType>({
      url: "/me/goals",
      queryParams,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async createGoal(body: GoalCreateType): Promise<CreateGoalResponse> {
    return this.httpWrapper.post<CreateGoalResponse>({
      url: "/me/goals",
      body,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getGatekeeperItems(programId: string, healthActivityResponseType: HealthContentActivityResponseType): Promise<GatekeeperItem[]> {
    return this.httpWrapper.get<GatekeeperItem[]>({
      url: `/me/program/${programId}/gatekeeper-items?healthActivityResponseType=${healthActivityResponseType}`,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getMyHealthProgram(): Promise<MyProgramResponse> {
    return this.httpWrapper.get<MyProgramResponse>({
      url: "/me/health/program",
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getGroups(
      limit: number,
      offset: number,
      joined?: boolean,
  ): Promise<PaginatedUserGroupResponse> {
    const queryParams: Record<string, any> = {
      limit,
      offset,
    };

    if (joined !== undefined) {
      queryParams.joined = joined;
    }

    return this.httpWrapper.get<PaginatedUserGroupResponse>({
      url: "/me/group",
      queryParams,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async getGroup(groupId: string): Promise<UserGroup> {
    return this.httpWrapper.get<UserGroup>({
      url: `/me/group/${groupId}`,
      authorization: this.sessionStorage.sessionToken!,
    })
  }

  public async joinGroup(groupId: string): Promise<void> {
    return this.httpWrapper.put<void>({
      url: `/me/group/${groupId}/join`,
      authorization: this.sessionStorage.sessionToken!,
    })
  }

  public async leaveGroup(groupId: string): Promise<void> {
    return this.httpWrapper.put<void>({
      url: `/me/group/${groupId}/leave`,
      authorization: this.sessionStorage.sessionToken!,
    })
  }

  public async getGroupFeed(
      userGroupId: string,
      limit: number,
      offset: number,
  ): Promise<PaginatedUserGroupFeedResponse> {
    const queryParams: Record<string, any> = {
      limit,
      offset,
    };

    return this.httpWrapper.get<PaginatedUserGroupFeedResponse>({
      url: `/me/group/${userGroupId}/post`,
      queryParams,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async createGroupPost(groupId: string, body: GroupPostCreate): Promise<void> {
    return this.httpWrapper.post<void>({
      url: `/me/group/${groupId}/post`,
      body,
      authorization: this.sessionStorage.sessionToken!,
    })
  }

  public async uploadGroupImage(file: File): Promise<{path: string}> {
    const body: FormData = new FormData();
    body.append("fileupload", file);
    return this.httpWrapper.postMultiData<{path: string}>({
      url: "/me/group/upload-image",
      body,
      authorization: this.sessionStorage.sessionToken!,
    });
  }

  public async deleteGroupPost(groupId: string, postId: string): Promise<void> {
    return this.httpWrapper.delete<void>({
      url: `/me/group/${groupId}/post/${postId}`,
      authorization: this.sessionStorage.sessionToken!,
    })
  }

  public async likeGroupPost(groupId: string, postId: string, body: GroupPostLike): Promise<GroupPostResponse> {
    return this.httpWrapper.put<GroupPostResponse>({
      url: `/me/group/${groupId}/post/${postId}/like`,
      body,
      authorization: this.sessionStorage.sessionToken!,
    })
  }

  public async addGroupPostComment(groupId: string, postId: string, body: { message: string }): Promise<GroupPostResponse> {
    return this.httpWrapper.post<GroupPostResponse>({
      url: `/me/group/${groupId}/post/${postId}/comment`,
      body,
      authorization: this.sessionStorage.sessionToken!,
    })
  }

  public async deleteGroupPostComment(groupId: string, postId: string, commentId: string): Promise<void> {
    return this.httpWrapper.delete<void>({
      url: `/me/group/${groupId}/post/${postId}/comment/${commentId}`,
      authorization: this.sessionStorage.sessionToken!,
    })
  }
}
