<template>
  <navbar-page
    :title="pathwayResponse.title"
    :display-right-nav="false"
    :back-link="$pagesPath.program.programPathway"
    subtitle="Pathway"
  >
    <div>
      <protected-image-curved-frame :src="pathwayResponse.coverPath" class="cover-url"/>
      <activity-description
        :reward="pathwayResponse.reward"
        :duration="pathwayResponse.duration / 60"
        :description="pathwayResponse.description"
      />
      <pathway-section-list
        :sections="pathwayResponse.sections"
        :finished="false"
      />
      <friends-finished-activity v-if="false" :friends="pathwayResponse.finishedFriends"/>
      <div class="buttons">
        <button-link
          :to="startPathWayPage"
          theme="white"
        >
          <img src="@/assets/img/button-start.svg"/>
          Start Pathway
        </button-link>
        <button-primary class="button-add" :loading="loading" @click="addPathwayToTodo" v-if="(pathwayResponse.status === 'available')">
          <img src="@/assets/img/button-add.svg"/>
          Add to My To-Do's
        </button-primary>
        <error-text :error="error"/>
      </div>
    </div>
  </navbar-page>
</template>

<script lang="ts">
import {
  Component,
  Prop,
} from "vue-property-decorator";
import ActivityDescription from "@/vue/molecules/activity-description.vue";
import ButtonPrimary from "@/vue/atoms/button-primary.vue";
import type {GetPathwayResponseDTO} from "@/ts/types/dto/pathway.dto";
import PathwaySectionList from "@/vue/molecules/pathway/pathway-section-list.vue";
import FriendsFinishedActivity from "@/vue/organisms/friends-finished-activity.vue";
import ErrorText from "@/vue/atoms/error-text.vue";
import NavbarPage from "@/vue/templates/navbar-page.vue";
import ProtectedImageCurvedFrame from "@/vue/atoms/protected-image-curved-frame.vue";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {mixins} from "vue-class-component";
import ButtonLink from "@/vue/atoms/button-link.vue";
import {GrowlsStoreMixin} from "@/ts/store/growl/growl-store-instance";

@Component({
  components: {
    ButtonLink,
    ProtectedImageCurvedFrame,
    NavbarPage,
    ErrorText,
    ActivityDescription,
    ButtonPrimary,
    PathwaySectionList,
    FriendsFinishedActivity,
  }
})
export default class PathwayStartPage extends mixins(LoadingMixin, GrowlsStoreMixin) {
  @Prop()
  pathwayResponse!: GetPathwayResponseDTO;

  get startPathWayPage(): string {
    return this.$pagesPath.pathway.pathwayTask
      .replace(":id", this.pathwayResponse.id)
      .replace(":number", "1");
  }

  @DefaultGrowlError
  async addPathwayToTodo(): Promise<void> {
    await this.$api.addPathwayToTodo(this.pathwayResponse.id);
    void this.growlsStore.growlInfo("Pathway has been successfully added to the TODO list");
  }
}

</script>

<style lang="sass" scoped>
.cover-url
  position: absolute
  top: 0
  right: 0

.title
  @include Asap700
  display: flex
  position: relative
  justify-content: center
  align-items: center
  color: $color-primary-hc-blue-100
  flex-direction: column

.type-activity
  @include Roboto600
  font-size: 16px
  text-transform: uppercase
  letter-spacing: 1.5px
  color: $color-tertiary-pink-100

.buttons
  display: flex
  justify-content: center
  margin: 5rem
  flex-direction: row
  align-items: center

.button-add
  margin-left: 2rem

</style>
