<template>
    <div >
      <div class="title-wrapper">
          <h1 class="title">
            My Motivation
          </h1>        
      <div class="subtitle">Write down your motivation for making or maintaining your health goals!</div>
    </div>

      <input-textarea
        id="motivation"
        v-model="motivationJustTyped"
        name="motivation"
        class="textarea"
        placeholder="Write down your motivation here for making or maintaining your health goals!"
      />
      <q-checkbox
        id="check-tos"
        v-model="termsOfServiceApproved"
        class="agree-checkbox"
        required
        name="check-tos"
      ></q-checkbox>
       <span class="agree-checkbox" style="padding-left:1%; padding-bottom:5%"> I agree to the
        <router-link :to="$pagesPath.auth.termsOfService" target="_blank" class="agree-checkbox-link">
          Terms of service
        </router-link>
        of HealthCheck360</span>
      <q-checkbox
        id="check-texting"
        v-model="textingAgreementApproved"
        class="agree-checkbox"
        name="check-texting"
      >
        I agree to receive SMS text messages from HealthCheck360 for my wellness program updates.
        <div class="texting-agreement-line">
          I understand that message and data rates may apply. Text STOP to unsubscribe and HELP for help.
        </div>
      </q-checkbox>

      <div class="privacy-policy-line">
        Learn more about the HealthCheck360
        <router-link :to="$pagesPath.auth.privacyPolicy" target="_blank" class="agree-checkbox-link">
          Privacy Policy
        </router-link>
      </div>
      <error-text v-if="!termsOfServiceApproved" :error="tosErr"/>

      <error-text :error="error"/>
    </div>
</template>

<script lang="ts">
import {Component, Prop} from "vue-property-decorator";
import InputText from "@/vue/atoms/input-text.vue";
import RegisterPage from "@/vue/templates/register-page.vue";
import InputCheck from "@/vue/atoms/input-check.vue";
import InputTextarea from "@/vue/atoms/input-textarea.vue";
import type {AuthRegisterRequestDTO, AuthLoginResponseDTO, CensusData} from "@/ts/types/dto/auth.dto";
import ErrorText from "@/vue/atoms/error-text.vue";
import {mixins} from "vue-class-component";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import type {
  AdditionalData,
  Interests,
  RegistrationData,
  VerificationData,
} from "@/ts/types/store/onboarding-store-types";
import {OnboardingState, OnboardingStoreMixin} from "@/ts/store/onboarding/onboarding-store-instance";
import {DefaultStoreMixin} from "@/ts/store/default/default-store-instance";

@Component({
  name: "Motivation",
  components: {
    ErrorText,
    InputTextarea,
    InputCheck,
    RegisterPage,
    InputText,
  },
})
export default class MotivationPageSso extends mixins(LoadingMixin, OnboardingStoreMixin, DefaultStoreMixin) {

  motivationJustTyped: string = "";

  termsOfServiceApproved: boolean = false;

  textingAgreementApproved: boolean = true;

  @Prop()
  asComponent!: boolean;

  @OnboardingState
  checkToken!: string | null;

  @OnboardingState
  motivation!: string | null;

  @OnboardingState
  userRegistrationData!: RegistrationData | null;

  @OnboardingState
  userVerificationData!: VerificationData | null;

  @OnboardingState
  userAdditionalData!: AdditionalData | null;

  
  @OnboardingState
  userCensusData!: CensusData | null;

  @OnboardingState
  interests!: Interests;

  tosErr!: string;

  created(): void {
    if(!this.asComponent){
      void this.$router.push(this.$pagesPath.auth.onboarding);
    }
    
    if (this.motivation) {
      this.motivationJustTyped = this.motivation;
    }

  }

  @DefaultGrowlError
  async submit(): Promise<void> {

    if(!this.termsOfServiceApproved){
      this.tosErr = 'Please agree to the terms of service to proceed';
      return;
    }
    // If data is missing onmout will revert to required page
    const data: AuthRegisterRequestDTO = {
      ...this.userAdditionalData!,
      username: this.userCensusData!.username,
      email: this.userAdditionalData!.email || '',
      password:'',
      interests: this.interests,
      gender: this.userAdditionalData!.gender ?? this.userCensusData!.gender,
      sex: this.userCensusData!.sex,
      dateOfBirth: this.userCensusData!.dateOfBirth,
      motivationQuote: this.motivationJustTyped,
      hasConsentedToTexting: this.textingAgreementApproved,
    };
    // save in case network error
    this.onboardingStore.setMotivation(this.motivationJustTyped);
    const response: AuthLoginResponseDTO = await this.$api.registerSso(data, this.checkToken!);
    console.log('response:',response);
    if(response !== undefined && 'sessionToken' in response){
      this.defaultStore.setAuthTokens(response);
      void this.$router.replace("/todo");
    }
    else{
      throw new Error("Registration login failed");
    }
    // clear data so it's not store if user decides to register another acc
    this.onboardingStore.setUserRegistrationData(null);
    this.onboardingStore.setUserVerificationData(null);
    this.onboardingStore.setAdditionalData(null);
    this.onboardingStore.setCompanyInformation(null);
    this.onboardingStore.setMotivation(null);
    this.onboardingStore.setCheckToken(null);
    const redirectUrl = localStorage.getItem("redirectUrl");

    if (localStorage.getItem("companyCode")) {
      localStorage.removeItem("companyCode");
    }

    if (redirectUrl) {
      console.log('here is where the phantom stuff happened, url:',redirectUrl);
      localStorage.removeItem("redirectUrl");
      //window.location.href = redirectUrl;
    }

  }

}
</script>

<style lang="sass" scoped>

.container
  max-width: 80%
  height: 450

.agree-checkbox
  @include Roboto700
  font-size: 14px
  margin-bottom: 10px

.agree-checkbox-link
  color: $color-secondary-state-blue-100
  font-weight: 700

.textarea
  margin-bottom: 15px

.texting-agreement-line
  margin-left: 38px

.privacy-policy-line
  margin-top: 15px
  margin-left: 15px
  font-size: 14px

.subtitle
  font-size: 16px
  letter-spacing: 0.1px
  @include Roboto400

.title-wrapper
  margin-bottom: 5rem
  text-align: center
  color: $color-primary-hc-blue-100

.title
  @include Asap700
  font-size: 28px
  margin: 0 0 8px  

</style>
