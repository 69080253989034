<template>
  <profile-field
    name="Email"
    :warning="warnTxt"
    :content="profile.email"
    :error="error"
    :loading="loading"
    :show-success="showSuccess"
    :is-enabled="true"
    @save="save"
  >
    <edit-email-input v-model="email" :existing-value-for-this-user="profile.email"/>
  </profile-field>
</template>

<script lang="ts">
import {Component} from "vue-property-decorator";
import ProfileField from "@/vue/organisms/profile/profile-field.vue";
import {DefaultState, DefaultStoreMixin} from "@/ts/store/default/default-store-instance";
import type {Profile} from "@/ts/types/store/default-store-types";
import type {PutProfileRequestDTO} from "@/ts/types/dto/profile.dto";
import {profileToPutProfileRequest} from "@/ts/utils/pure-functions";
import EditEmailInput from "@/vue/molecules/edit-field/edit-email-input.vue";
import {
  DefaultGrowlError,
  LoadingMixin,
} from "@/ts/mixins/loading-mixin";
import {mixins} from "vue-class-component";

@Component({
  name: 'ProfileFieldEmail',
  components: {EditEmailInput, ProfileField, }
})
export default class ProfileFieldEmail extends mixins(LoadingMixin, DefaultStoreMixin) {
  @DefaultState
  profile!: Profile;

  showSuccess: boolean = false;

  email: string = "";

  get warnTxt(): string | null {
    if (!this.profile.email) {
      return "Please add an Email address";
    } else if (this.profile.emailConfirmationStatus === "pending") {
      return "Confirm your Email address";
    } else if (this.profile.emailConfirmationStatus === "not_confirmed") {
      return "Your Email address is not confirmed";
    }
    return null;
  }

  created(): void {
    this.email = this.profile.email;
  }

  @DefaultGrowlError
  async save(): Promise<void> {
    this.showSuccess = false;
    const value: PutProfileRequestDTO = {
      ...profileToPutProfileRequest(this.profile),
      email: this.email,
    };
    const getProfileResponseDTO = await this.$api.updateProfile(value);
    const profileData: Profile = {
      ...getProfileResponseDTO,
      dateOfBirth: this.profile.dateOfBirth,
      location: this.profile.location,
      department: this.profile.department,
      myCareEnabled: this.profile.myCareEnabled,
    };
    this.defaultStore.setProfile(profileData);
    this.showSuccess = true;
  }
}
</script>
<!-- eslint-disable -->
<style lang="sass" scoped>

</style>
